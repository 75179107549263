import { useEffect, useState } from "react";
import { TokenService } from "../services/Token.service";
import { useBlock } from "../../../../common/hooks/useBlock";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { parseUnits } from "ethers";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useAllowance = (
  value: string,
  spender?: string,
  token?: IToken,
) => {
  const [allowance, setAllowance] = useState(0n);
  const [isApproving, setIsApproving] = useState(false);
  const block = useBlock();
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  const approve = async (amount: string) => {
    if (address && walletProvider && token && spender) {
      setIsApproving(true);
      const tokens = new TokenService(token);
      await tokens.approve(
        spender,
        walletProvider,
        parseUnits(amount, token.decimals).toString(),
      );
      setIsApproving(false);
    }
  };

  useEffect(() => {
    if (address && walletProvider && token && spender) {
      (async () => {
        if (token && !token.isEther) {
          const tokens = new TokenService(token);
          const allowance = await tokens.getAllowance(
            address,
            spender,
            walletProvider,
          );

          setAllowance(allowance);
        }
      })();
    }
  }, [address, spender, token, walletProvider, block]);

  return {
    approve,
    isAllowance: token?.isEther
      ? true
      : value.length
        ? parseUnits(value, token?.decimals) <= allowance
        : false,
    isApproving,
  };
};
