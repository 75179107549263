import { useEffect, useState } from "react";
import { PoolsService } from "@curiodao/capital-dex-sdk/polkadot";
import { useWalletContext } from "../../../../common/context/Wallet";
import { IEstimatedAddResult } from "../interfaces/IEstimatedAddResult";
import { useBlock } from "../../../../common/hooks/useBlock";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useEstimatedPool = (
  token0: IToken | undefined,
  token1: IToken | undefined,
  inputFrom: string,
  inputTo: string,
  noCalculate?: boolean,
) => {
  const { account, api } = useWalletContext();
  const block = useBlock();

  const [addEstimate, setAddEstimate] = useState<
    IEstimatedAddResult | undefined
  >();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      account &&
      token0 &&
      token1 &&
      !!inputFrom.length &&
      !!inputTo.length &&
      !noCalculate &&
      api
    ) {
      const poolService = new PoolsService(api, account);
      setLoading(true);
      poolService
        .getAddEstimate(token0, token1, inputFrom, inputTo)
        .then((res) => {
          setAddEstimate(res);
        });
      setLoading(false);
    }
  }, [account, api, noCalculate, inputFrom, inputTo, token0, token1, block]);

  return addEstimate
    ? {
        ...addEstimate,
        isFirstLiquidityProvider: addEstimate?.incrementShare === 1,
        loading,
      }
    : undefined;
};
