import { useState } from "react";
import classes from "./RemoveTransactionModal.module.css";
import {
  InfoRow,
  Modal,
  PoolPanel,
  StatusModal,
  SwapIcon,
} from "@curiodao/capital-dex-ui-kit";
import { Token } from "@uniswap/sdk";
import { useNetworkContext } from "../../../../Network";
import { Eip1193Provider } from "ethers";
import { getBlockExplorerUrl } from "../../../../../common/_evm/libs/getBlockExplorerUrl";
import { PoolsService } from "@curiodao/capital-dex-sdk/evm";
import { IToken } from "@curiodao/capital-dex-sdk";

interface ITransactionModal {
  token0Amount: string;
  token1Amount: string;
  token0: IToken;
  token1: IToken;
  tokenA: Token | undefined;
  tokenB: Token | undefined;
  account: string;
  onCloseButtonClick: () => void;
  walletProvider: Eip1193Provider;
  rates: string | undefined;
  shareToRemove: bigint;
  burned: string;
}

export const RemoveTransactionModal = ({
  token0Amount,
  token1Amount,
  token0,
  token1,
  tokenA,
  tokenB,
  walletProvider,
  account,
  onCloseButtonClick,
  rates,
  shareToRemove,
  burned,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);

  const [tx, setTx] = useState<string>("");
  const { core } = useNetworkContext();

  const handle = async () => {
    setReview(false);
    if (core && tokenA && tokenB) {
      const poolService = new PoolsService();
      try {
        if (shareToRemove) {
          const txHash = await poolService.remove(
            tokenA,
            tokenB,
            shareToRemove,
            core?.router,
            !!token0.isEther || !!token1.isEther,
            !!token1.isEther,
            account,
            walletProvider,
          );

          if (txHash) {
            setTx(txHash);
            setStatus("done");
          } else {
            setStatus("error");
          }
        }
      } catch (e) {
        console.error(e);
        setStatus("error");
      }
    }
  };
  return (
    <Modal title={"Remove"} onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.Confirm}>
          <PoolPanel
            title="Receive"
            value0={token0Amount}
            value1={token1Amount}
            tokens={[token0, token1]}
            bordered
          />
          <div className="card bordered">
            <InfoRow
              title="Burned"
              value={`${burned} ${token0.symbol}-${token1.symbol}`}
            />
            <InfoRow
              title="Rates"
              value={
                <>
                  1 {token0.symbol} = {`${rates} ${token1.symbol}`}
                  <SwapIcon />
                </>
              }
            />
          </div>
          <button className="btn block" onClick={handle}>
            Confirm removing
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action={"removing"}
          token0={token0}
          token1={token1}
          token0value={token0Amount.toString()}
          token1value={token1Amount.toString()}
          transactionLink={getBlockExplorerUrl(tx)}
        />
      )}
    </Modal>
  );
};
