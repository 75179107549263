import classNames from "classnames";
import classes from "./Footer.module.css";
import { FOOTER_LINKS } from "../constants/footerLinks";
import { SOCIAL_LINKS } from "../constants/socialLinks";
import React from "react";
import { Link } from "react-router-dom";
import { useNetworkContext } from "../../Network";

export const Footer = ({ isLanding }: { isLanding?: boolean }) => {
  const { isParachain } = useNetworkContext();
  return (
    <footer
      className={classNames(
        isLanding ? classes.LandingFooter : "",
        classes.Footer,
      )}
    >
      <div className={classes.TopSide}>
        <p className={classNames("p3", classes.Copyrights)}>
          © 2024 Capital DEX
        </p>
        <ul className={classNames(classes.Links, "p3 semi")}>
          {FOOTER_LINKS.map((link) => (
            <li key={link.name}>
              <a href={link.path} target="_blank" rel="noreferrer">
                {link.name}
              </a>
            </li>
          ))}
          {!isParachain && (
            <li>
              <Link to="/help">How to switch network</Link>
            </li>
          )}
        </ul>
        <ul className={classes.Social}>
          {SOCIAL_LINKS.map((item) => (
            <li key={item.name}>
              <a href={item.link}>{item.icon}</a>
            </li>
          ))}
        </ul>
      </div>
      <div className={classNames("space-between", classes.BottomSide)}>
        <p className="p3">
          CurioDAO Ecosystem and Capital DEX are not available in the United
          States or other prohibited jurisdictions.
        </p>
        <p className="p3">Made With ♡ in Switzerland</p>
      </div>
    </footer>
  );
};
