import { Header } from "../Header";
import { Footer } from "../Footer";
import classes from "./Layout.module.css";
import classNames from "classnames";
import { Outlet } from "react-router-dom";
import { Banner } from "../../components/Banner";
import { useInitNetwork } from "../Network/context/Network/useInitNetwork";

export const Layout = () => {
  useInitNetwork();

  return (
    <div className={classNames("bg", classes.Layout)}>
      <Banner />
      <Header />
      <main className="container">
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};
