import { useEffect, useState } from "react";
import { useWalletContext } from "../context/Wallet";

export const useBlock = () => {
  const { api } = useWalletContext();
  const [block, setBlock] = useState(0n);

  useEffect(() => {
    if (api) {
      (async () => {
        await api.rpc.chain.subscribeNewHeads(({ number }) => {
          setBlock(number.toBigInt());
        });
      })();
    }
  }, [api]);

  return block;
};
