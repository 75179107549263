import { useEffect, useState } from "react";
import { useNetworkContext } from "../../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { FarmService, IFarmPool } from "@curiodao/capital-dex-sdk/evm";

export const usePoolBalances = (pool: IFarmPool, account?: string) => {
  const { core, chainId } = useNetworkContext();
  const [staked, setStaked] = useState<bigint>(0n);
  const [balance, setBalance] = useState<bigint>(0n);
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (core && pool && account && walletProvider) {
      (async () => {
        setLoading(true);
        const farms = new FarmService(pool);
        const staked = await farms.getStaked(account, walletProvider);
        const userBalance = await farms.getBalance(account, walletProvider);
        setStaked(staked);
        setBalance(userBalance);
        setLoading(false);
      })();
    }
  }, [core, chainId, account, pool, walletProvider]);

  return { staked, balance, loading };
};
