import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const testnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.SKALE_TESTNET,
    name: "SKALE Testnet",
    rpcUrl: "https://staging-v2.skalenodes.com/v1/fit-graffias",
    currency: "skETH",
    explorerUrl: "https://fit-graffias.explorer.staging-v2.skalenodes.com",
  },
  cgt: "0x026aAED0178856f93a4eB4Bec1ab2A86eFf29222",
  whitelist: "0xB7092f6ED01f56BA19d78D225E26aDfa9974F9d5",
  router: "0xD5A9dC5c1BB41Cdee922c74606caF321FeB17e2b",
  multicall: "0x11384996AD2a024AD5417Cce6300a234FB91B120",
  blocksPerYear: 2866909,
  farming: [
    {
      masterChef: "0x017106899C2d7BD5bf1e4af7ab6e1b7fC29Acc77",
      rewardToken: "CGT",
      rewardPerMonth: 50000,
      pools: [
        {
          pairAddress: "0xF64a1079A9BFaAb8CA92df7Fc636FDb12B8AA4CF",
          pid: 0,
          token0Symbol: "CGT",
          token1Symbol: "DAI",
        },
        {
          pairAddress: "0x3374007334630c06256d6C5Bb654FC829C7D190E",
          pid: 1,
          token0Symbol: "CGT",
          token1Symbol: "ETH",
        },
        {
          pairAddress: "0x2b6f332398bA95C53Ac34Fc99B0D74A786f45049",
          pid: 2,
          token0Symbol: "CGT",
          token1Symbol: "CSC",
        },
        {
          pairAddress: "0x433D3e7A594555DBEE7955b2ac497c97B66EC9a6",
          pid: 3,
          token0Symbol: "CGT",
          token1Symbol: "SKL",
        },
      ],
    },
  ],
};
