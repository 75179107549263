import { isTestnet } from "../../../../core/isTesnet";
import { PARACHAIN_TOKENS_LIST } from "../../../../contexts/TokenList/constants";
import { IFarmPool } from "@curiodao/capital-dex-sdk/polkadot";

export const REWARD_PER_MONTH = isTestnet
  ? { CGT: "150000", BSX: "6500000" }
  : { CGT: "75000", BSX: "6500000" };

export const HARVEST_TOKENS = ["CGT" /*, "BSX"*/];

export const MAINNET_PARACHAIN_POOLS: IFarmPool[] = [
  {
    id: "CGT-USDC",
    token0: PARACHAIN_TOKENS_LIST[0],
    token1: PARACHAIN_TOKENS_LIST[2],
    rewardTokens: ["CGT"],
  },
  // {
  //   id: "CGT-BSX",
  //   token0: PARACHAIN_TOKENS_LIST[0],
  //   token1: PARACHAIN_TOKENS_LIST[3],
  //   rewardTokens: ["CGT", "BSX"],
  // },
];

export const TESTNET_PARACHAIN_POOLS: IFarmPool[] = [
  ...MAINNET_PARACHAIN_POOLS,
  {
    id: "CGT-DAI",
    token0: PARACHAIN_TOKENS_LIST[0],
    token1: PARACHAIN_TOKENS_LIST[1],
    rewardTokens: ["CGT"],
  },
];

export const PARACHAIN_POOLS: IFarmPool[] = isTestnet
  ? TESTNET_PARACHAIN_POOLS
  : MAINNET_PARACHAIN_POOLS;
