import { useEffect, useState } from "react";
import { IToken } from "@curiodao/capital-dex-sdk";
import { PoolsService } from "../services/pools.service";
import { ILiquidity } from "../interfaces/ILiquidity";

export const useSimulateProvideLiquidity = (
  token0: IToken | undefined,
  token1: IToken | undefined,
  inputFrom: string | undefined,
  inputTo: string | undefined,
  noCalculate?: boolean,
) => {
  const [liquidity, setLiquidity] = useState<ILiquidity | undefined>();
  const [loading, setLoading] = useState(false);
  const [isFirstLiquidityProvider, setIsFirstLiquidityProvider] =
    useState(false);

  useEffect(() => {
    if (token0 && token1 && inputFrom && inputTo && !noCalculate) {
      setLoading(true);
      const poolsService = new PoolsService();
      poolsService
        .getExpectedTokens(token0, token1, inputFrom, inputTo)
        .then((res) => {
          if (res) {
            setLiquidity({
              lpBalance: res.lpBalance,
              userShare: res.userShare,
            });
            setIsFirstLiquidityProvider(!Number(res.lpBalance));
          }
          setLoading(false);
        });
    } else {
      setLiquidity(undefined);
    }
  }, [inputFrom, inputTo, noCalculate, token0, token1]);

  return {
    liquidity,
    loading,
    isFirstLiquidityProvider,
  };
};
