import classNames from "classnames";
import classes from "./HelpSection.module.css";
import {
  Tabs,
  Tab,
  PageDescription,
  HelpList,
} from "@curiodao/capital-dex-ui-kit";
import React, { useState } from "react";
import img from "./../assets/Help-img.png";
import { NetworkService } from "../../Network";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";

export const HelpSection = () => {
  const [networkService] = useState(() => new NetworkService());
  const list = networkService.getEVMList();
  const [active, setActive] = useState(list[0].id);
  const networkCore = networkService.getNetworkCore(active);
  const { walletProvider } = useWeb3ModalProvider();

  const handleAdd = async () => {
    if (walletProvider) {
      networkService.switchNetwork(active, walletProvider);
    }
  };

  return (
    <div className="card">
      <Tabs isFullWidth>
        {list.map((item) => (
          <Tab
            active={active === item.id}
            onClick={() => setActive(item.id)}
            key={item.id}
          >
            {item.name}
          </Tab>
        ))}
      </Tabs>
      <p className="p1 semi">
        Switch Metamask to{" "}
        <span className={classes.Uppercase}>
          {networkCore?.networkCredentials.name}
        </span>{" "}
        network
      </p>
      <div className={classes.InnerContent}>
        <PageDescription img={img} text="" />
        <p className={classNames("p2", classes.Description)}>
          In order to set up the{" "}
          <span className={classes.Uppercase}>
            {networkCore?.networkCredentials.name}
          </span>{" "}
          network in the MetaMask extension, you need to take{" "}
          {networkCore?.name === "Skale" ? 10 : 8} steps:
        </p>
        <HelpList>
          <li className="p2">
            <span>Go to the Metamask extension</span>
          </li>
          <li className="p2">
            <span>
              Click on the wallet <b>LOGO</b> and select the <b>SETTINGS</b>{" "}
              section
            </span>
          </li>
          <li className="p2">
            <span>
              Select the <b>NETWORKS</b> section and click <b>ADD NETWORK</b>
            </span>
          </li>
          <li className="p2">
            <div className={classes.ExtendedStep}>
              <span>
                Fill in the fields as shown in the example and click <b>SAVE</b>
              </span>
              <div className="card gray gap-16">
                <div className={classes.CardItem}>
                  <p className="p3">Network Name</p>
                  <p className="p3 semi">
                    {networkCore?.networkCredentials.name}
                  </p>
                </div>
                <div className={classes.CardItem}>
                  <p className="p3">New RPC URL</p>
                  <p className="p3 semi">
                    {networkCore?.networkCredentials.rpcUrl}
                  </p>
                </div>
                <div className={classes.CardItem}>
                  <p className="p3">ChainID</p>
                  <p className="p3 semi">
                    {networkCore?.networkCredentials.chainId}
                  </p>
                </div>
                <div className={classes.CardItem}>
                  <p className="p3">Symbol</p>
                  <p className="p3 semi">
                    {networkCore?.networkCredentials.currency}
                  </p>
                </div>
                <div className={classes.CardItem}>
                  <p className="p3">Block Explorer URL</p>
                  <p className="p3 semi">
                    {networkCore?.networkCredentials.explorerUrl}
                  </p>
                </div>
                <button
                  className="btn secondary small"
                  onClick={() => handleAdd()}
                >
                  Add to Metamask
                </button>
              </div>
            </div>
          </li>
          <li className="p2">
            <span>
              Activate the emerging{" "}
              <span className={classes.Uppercase}>
                {networkCore?.networkCredentials.name}
              </span>{" "}
              in the Metamask extension
            </span>
          </li>
          <li className="p2">
            <span>
              Add CGT {networkCore?.name === "Skale" ? "and ETH " : ""}token on{" "}
              <span className={classes.Uppercase}>
                {networkCore?.networkCredentials.name}
              </span>{" "}
              to Metamask. Click <b>Assets</b> and <b>ADD TOKEN</b>
            </span>
          </li>
          <li className="p2">
            <span>
              Click <b>CUSTOM TOKEN</b>. Enter the contract address{" "}
              <b>{networkCore?.cgt}</b> and click <b>NEXT</b>
            </span>
          </li>
          <li className="p2">
            <span>
              CGT token on{" "}
              <span className={classes.Uppercase}>
                {networkCore?.networkCredentials.name}
              </span>{" "}
              should appear in your wallet
            </span>
          </li>
          {networkCore?.name === "Skale" ? (
            <>
              <li className="p2">
                <span>
                  Click <b>ADD TOKEN</b> and <b>CUSTOM TOKEN</b>. Enter the
                  address <b>0xD2Aaa00700000000000000000000000000000000</b>,
                  click <b>EDIT</b> token name as ETH and click <b>NEXT</b>
                </span>
              </li>
              <li className="p2">
                <span>
                  ETH token on{" "}
                  <span className={classes.Uppercase}>
                    {networkCore?.networkCredentials.name}
                  </span>{" "}
                  should appear in your wallet. This token is equal to ETH on
                  Ethereum
                </span>
              </li>
            </>
          ) : (
            <div />
          )}
        </HelpList>
      </div>
    </div>
  );
};
