import {
  BrowserProvider,
  ethers,
  Eip1193Provider,
  JsonRpcProvider,
} from "ethers";

export const getProvider = (
  walletProvider: Eip1193Provider | undefined,
  rpcLink?: string,
): JsonRpcProvider | BrowserProvider => {
  if (walletProvider) {
    return new BrowserProvider(walletProvider);
  }
  return new ethers.JsonRpcProvider(rpcLink);
};
