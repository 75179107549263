import { Modal, Processing, TokenPanel } from "@curiodao/capital-dex-ui-kit";
import { IPool } from "../../../../Pools/_parachain/interfaces/pool";
import { useState } from "react";
import classes from "./StakingModal.module.css";
import { FarmsService } from "@curiodao/capital-dex-sdk/polkadot";
import { useWalletContext } from "../../../../../common/context/Wallet";
import { toToken } from "../../../../../common/libs/support";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { IFarmPool } from "@curiodao/capital-dex-sdk/polkadot";

interface StakingModalProps {
  farmPool: IFarmPool;
  pool: IPool;
  isDeposit?: boolean;
  onCloseButtonClick: () => void;
  staked: Web3Num | undefined;
}

export const StakingModal = ({
  farmPool,
  pool,
  isDeposit,
  onCloseButtonClick,
  staked,
}: StakingModalProps) => {
  const { account, api, injector } = useWalletContext();
  const [value, setValue] = useState("");
  const [isPending, setPending] = useState(false);

  const handleAction = async () => {
    try {
      if (account && injector && api) {
        setPending(true);
        const farms = new FarmsService(api);
        const unsub = await farms[isDeposit ? "deposit" : "withdraw"](
          pool.dexShare,
          value,
        ).signAndSend(account, { signer: injector.signer }, ({ status }) => {
          if (status.isInBlock || status.isFinalized) {
            setPending(false);
            unsub();
          }
        });
      }
    } catch (e) {
      console.error(e);
      setPending(false);
    }
  };

  return (
    <Modal
      title={`${isDeposit ? "Deposit" : "Withdraw"} ${farmPool.id} CLP`}
      onCloseButtonClick={onCloseButtonClick}
    >
      <div className={classes.Wrapper}>
        <TokenPanel
          token0={farmPool.token0}
          token1={farmPool.token1}
          value={value}
          tokenBalance={
            isDeposit
              ? getShowBalance(pool?.userShare, farmPool.token0.decimals)
              : staked?.dsp ?? "0"
          }
          setValue={setValue}
        />
        <button
          className="btn block"
          disabled={
            !value.length || isDeposit
              ? toToken(value) > pool?.userShare
              : toToken(value) > (staked?.bn ?? 0n) || isPending
          }
          onClick={handleAction}
        >
          {isPending ? (
            <Processing />
          ) : value.length ? (
            `Confirm ${isDeposit ? "deposit" : "withdraw"}`
          ) : (
            "Enter amount"
          )}
        </button>
      </div>
    </Modal>
  );
};
