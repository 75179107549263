import curioParachain from "../../assets/networks/curio-parachain.svg";
import ton from "../../assets/networks/ton.svg";
import bitspectrum from "../../assets/networks/bitspectrum.svg";

export const parachainNetworks = [
  { name: "Curio", id: "curio-parachain", icon: curioParachain },
  { name: "L2 TON (soon)", id: "l2-ton", icon: ton, disabled: true },
  {
    name: "BitSpectrum (soon)",
    id: "bitspectrum",
    icon: bitspectrum,
    disabled: true,
  },
];
