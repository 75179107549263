import classes from "./RemoveLiquidity.module.css";
import {
  BackLink,
  InfoRow,
  SwapIcon,
  Tooltip,
  useModalByName,
  Processing,
  TokenPanel,
  PoolPanel,
  TokensPanel,
} from "@curiodao/capital-dex-ui-kit";
import React, { useState } from "react";
import classNames from "classnames";
import { ConnectWalletModal } from "../../../../Account/components/ConnectWalletModal";
import { useNavigate } from "react-router-dom";
import { useWalletContext } from "../../../../../common/context/Wallet";
import { useEstimatedPool } from "../../hooks/useEstimatedPool";
import { RemoveTransactionModal } from "../RemoveTransactionModal";
import { toHuman, toToken } from "../../../../../common/libs/support";
import { usePool, usePoolByTokens } from "../../hooks/usePool";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { IToken } from "@curiodao/capital-dex-sdk";
import Skeleton from "react-loading-skeleton";

interface IRemoveLiquidity {
  tokens: IToken[];
}

export const RemoveLiquidity = ({ tokens }: IRemoveLiquidity) => {
  const navigate = useNavigate();
  const { modal, closeModal, changeModal } = useModalByName();
  const { account, injector, api } = useWalletContext();
  const [inputFrom, setInputFrom] = useState("");

  const pool = usePoolByTokens(tokens[0], tokens[1], account);
  const { poolInfo } = usePool(pool, account);

  const error =
    !tokens[0] || !tokens[1]
      ? "Select tokens"
      : !inputFrom.length && parseFloat(inputFrom) === 0
        ? "Enter amount"
        : pool?.userShare && toToken(inputFrom) > pool.userShare
          ? `Insufficient user share balance`
          : undefined;

  const estimatedPool = useEstimatedPool(tokens[0], tokens[1], "1", "1");
  const factor = pool?.userShare
    ? toToken(inputFrom) > pool.userShare
      ? 0
      : Number(toToken(inputFrom)) / Number(pool.userShare)
    : 0;

  const tokenAReturns = (
    poolInfo
      ? parseFloat(toHuman(poolInfo.tokenAReturns, tokens[0].decimals)) * factor
      : 0
  ).toFixed(4);
  const tokenBReturns = (
    poolInfo
      ? parseFloat(toHuman(poolInfo.tokenBReturns, tokens[1].decimals)) * factor
      : 0
  ).toFixed(4);

  return (
    <div className={classNames(classes.Wrapper, "card")}>
      <div className={classes.Headline}>
        <BackLink text="Remove liquidity" onClick={() => navigate(-1)} />
        <Tooltip text="Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to your share of the pool. Accrued fees are included in the amounts you receive." />
      </div>
      <TokensPanel>
        <TokenPanel
          title="Input"
          token0={tokens[0]}
          token1={tokens[1]}
          value={inputFrom}
          setValue={setInputFrom}
          tokenBalance={getShowBalance(pool?.userShare, tokens[0].decimals)}
        />
        <PoolPanel
          title="Output"
          value0={tokenAReturns}
          value1={tokenBReturns}
          tokens={tokens}
        />
      </TokensPanel>
      {estimatedPool &&
        tokens[0] &&
        tokens[1] &&
        (estimatedPool.loading ? (
          <Skeleton height={51} count={1} />
        ) : (
          <div className="card bordered">
            <InfoRow
              title="Rates"
              value={
                <>
                  1 {tokens[0].symbol} ={" "}
                  {getShowBalance(estimatedPool.incrementB, tokens[1].decimals)}{" "}
                  {tokens[1].symbol}
                  <SwapIcon />
                </>
              }
            />
          </div>
        ))}
      {account ? (
        <button
          className={"btn block"}
          onClick={() => changeModal("remove")}
          disabled={!!error || modal === "remove"}
        >
          {error ?? (modal === "remove" ? <Processing /> : "Remove")}
        </button>
      ) : (
        <button className={"btn block"} onClick={() => changeModal("wallet")}>
          Connect wallet
        </button>
      )}
      {modal === "wallet" && <ConnectWalletModal toggleModal={closeModal} />}
      {modal === "remove" &&
        injector &&
        account &&
        api &&
        pool &&
        tokens[0] &&
        tokens[1] &&
        estimatedPool && (
          <RemoveTransactionModal
            token0Amount={tokenAReturns}
            token1Amount={tokenBReturns}
            token0={tokens[0]}
            token1={tokens[1]}
            api={api}
            account={account}
            injector={injector}
            onCloseButtonClick={() => closeModal()}
            shareToRemove={factor === 1 ? pool.userShare : toToken(inputFrom)}
            rates={getShowBalance(estimatedPool.incrementB, tokens[1].decimals)}
          />
        )}
    </div>
  );
};
