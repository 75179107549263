import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useNetworkContext } from "../../../Network";
import { useEffect, useState } from "react";
import { IFarmPool, FarmService } from "@curiodao/capital-dex-sdk/evm";

export const useHarvest = (pool: IFarmPool, account?: string) => {
  const { walletProvider } = useWeb3ModalProvider();
  const { core } = useNetworkContext();
  const [rewards, setRewards] = useState<bigint>(0n);
  const [isHarvesting, setIsHarvesting] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (core && pool && account && walletProvider) {
      (async () => {
        setLoading(true);
        const farms = new FarmService(pool);
        const earned = await farms.getPoolReward(account, walletProvider);
        setRewards(earned);
        setLoading(false);
      })();
    }
  }, [core, account, pool, walletProvider]);

  const harvest = async () => {
    if (core && pool && walletProvider) {
      setIsHarvesting(true);
      const farm = new FarmService(pool);
      await farm.harvest(walletProvider);
      setIsHarvesting(false);
    }
  };

  return {
    harvest,
    isHarvesting,
    rewards,
    loading,
  };
};
