import { Input, Modal, TokenRow } from "@curiodao/capital-dex-ui-kit";
import React, { useState } from "react";
import classes from "./SelectTokenModal.module.css";
import { isTestnet } from "../../../../core/isTesnet";
import { useTokenListContext } from "../../../../contexts/TokenList";
import Skeleton from "react-loading-skeleton";
import { IToken } from "@curiodao/capital-dex-sdk";

export const SelectTokenModal = ({
  toggleModal,
  selectToken,
  token,
}: {
  toggleModal: () => void;
  selectToken: (token: IToken) => void;
  token: IToken | undefined;
}) => {
  const { tokenList, loading } = useTokenListContext();
  const [searchTerm, setSearchTerm] = useState("");

  console.log("tokenList", tokenList);

  const filteredTokens = tokenList.filter(
    (item) =>
      (item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.symbol.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (isTestnet ? true : !item.onlyTestnet),
  );
  return (
    <Modal onCloseButtonClick={toggleModal} title="Select a token">
      <div className={classes.ModalInner}>
        <Input>
          <input
            placeholder="Search name or paste address"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </Input>
        {loading ? (
          <Skeleton height={44} count={5} />
        ) : (
          <ul className={classes.TokensList}>
            {filteredTokens
              .filter((item) => item.symbol !== token?.symbol)
              .map((token) => (
                <li key={`${token.name}-${token.symbol}`}>
                  <TokenRow
                    token={token}
                    onClick={() => {
                      selectToken(token);
                      toggleModal();
                    }}
                  />
                </li>
              ))}
          </ul>
        )}
      </div>
    </Modal>
  );
};
