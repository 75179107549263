import { useState } from "react";
import classes from "./AddTransactionModal.module.css";
import {
  InfoRow,
  Modal,
  StatusModal,
  SwapIcon,
  TokenPanel,
} from "@curiodao/capital-dex-ui-kit";
import { PoolsService } from "@curiodao/capital-dex-sdk/evm";
import { Token } from "@uniswap/sdk";
import { useNetworkContext } from "../../../../Network";
import { Eip1193Provider, parseUnits } from "ethers";
import { getBlockExplorerUrl } from "../../../../../common/_evm/libs/getBlockExplorerUrl";
import { IToken } from "@curiodao/capital-dex-sdk";

interface ITransactionModal {
  token0Amount: string;
  token1Amount: string;
  token0: IToken;
  token1: IToken;
  tokenA: Token | undefined;
  tokenB: Token | undefined;
  account: string;
  onCloseButtonClick: () => void;
  walletProvider: Eip1193Provider;
  rates: string | undefined;
  poolShare: string | undefined;
  lpTokenAmount: string;
}

export const AddTransactionModal = ({
  token0Amount,
  token1Amount,
  token0,
  token1,
  tokenA,
  tokenB,
  walletProvider,
  account,
  onCloseButtonClick,
  rates,
  poolShare,
  lpTokenAmount,
}: ITransactionModal) => {
  const [status, setStatus] = useState<Status>("loading");
  const [isReview, setReview] = useState(true);

  const [tx, setTx] = useState<string>("");
  const { core } = useNetworkContext();

  const handle = async () => {
    setReview(false);
    if (core && tokenA && tokenB) {
      const poolService = new PoolsService();
      try {
        const txHash = await poolService.add(
          tokenA,
          tokenB,
          parseUnits(token0Amount, tokenA.decimals),
          parseUnits(token1Amount, tokenB.decimals),
          core?.router,
          !!token0.isEther || !!token1.isEther,
          !!token1.isEther,
          account,
          walletProvider,
        );

        if (txHash) {
          setTx(txHash);
          setStatus("done");
        } else {
          setStatus("error");
        }
      } catch (e) {
        console.error(e);
        setStatus("error");
      }
    }
  };

  return (
    <Modal title={"Review supply"} onCloseButtonClick={onCloseButtonClick}>
      {isReview ? (
        <div className={classes.Confirm}>
          <TokenPanel
            token0={token0}
            token1={token1}
            value={lpTokenAmount}
            title="Receive"
            bordered
          />
          <div className="card bordered">
            <InfoRow
              title="Rates"
              value={
                <>
                  1 {token0.symbol} = {`${rates} ${token1.symbol}`}
                  <SwapIcon />
                </>
              }
            />
            <InfoRow
              title={`${token0.symbol} deposited`}
              value={token0Amount}
            />
            <InfoRow
              title={`${token1.symbol} deposited`}
              value={token1Amount}
            />
            <InfoRow
              title="Share of pool"
              value={`${Number(poolShare) * 100}%`}
            />
          </div>
          <button className="btn block" onClick={handle}>
            Confirm supply
          </button>
        </div>
      ) : (
        <StatusModal
          status={status}
          action={"supply"}
          token0={token0}
          token1={token1}
          token0value={token0Amount.toString()}
          token1value={token1Amount.toString()}
          transactionLink={getBlockExplorerUrl(tx)}
        />
      )}
    </Modal>
  );
};
