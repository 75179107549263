import classes from "./AddEthLiquidity.module.css";
import {
  BackLink,
  InfoRow,
  Processing,
  SwapIcon,
  TokenPanel,
  TokensPanel,
  Tooltip,
  useModalByName,
} from "@curiodao/capital-dex-ui-kit";
import { useEffect, useState } from "react";
import classNames from "classnames";
import {
  SelectTokenModal,
  getUniswapToken,
  useAllowance,
} from "../../../../Tokens";
import { useNavigate } from "react-router-dom";
import { useEthAddLiquidityParams } from "../../hooks/useAddLiquidityParams";
import { useTokensPanel } from "../../../../TokensPanel/_evm/hooks/useTokensPanel";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { toToken } from "../../../../../common/libs/support";
import { AddTransactionModal } from "../AddTransactionModal";
import { useNetworkContext } from "../../../../Network";
import { useTokenListContext } from "../../../../../contexts/TokenList";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import Skeleton from "react-loading-skeleton";

export const AddEthLiquidity = ({ pair }: { pair?: string }) => {
  const navigate = useNavigate();
  const { modal, closeModal, changeModal } = useModalByName();
  const { tokenList } = useTokenListContext();
  const { walletProvider } = useWeb3ModalProvider();
  const [inputFrom, setInputFrom] = useState("");
  const [inputTo, setInputTo] = useState("");
  const { address } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();
  const { core } = useNetworkContext();

  const {
    tokens,
    selectToken,
    token0Balance,
    token1Balance,
    setTokens,
    error,
  } = useTokensPanel(address, modal, inputFrom, tokenList, pair?.split("-"));

  const { addLiquidityParams, loading } = useEthAddLiquidityParams(
    inputFrom,
    tokens[0],
    tokens[1],
  );

  const calculatedInputTo = addLiquidityParams?.outputAmount ?? "";

  useEffect(() => {
    setInputTo(calculatedInputTo);
  }, [calculatedInputTo]);

  const {
    approve: approveToken0,
    isAllowance: isAllowanceToken0,
    isApproving: isApprovingToken0,
  } = useAllowance(inputFrom, core?.router, tokens[0]);

  const {
    approve: approveToken1,
    isAllowance: isAllowanceToken1,
    isApproving: isApprovingToken1,
  } = useAllowance(inputTo, core?.router, tokens[1]);

  return (
    <div className={classNames(classes.Wrapper, "card")}>
      <div className={classes.Headline}>
        <BackLink text="Add liquidity" onClick={() => navigate(-1)} />
        <Tooltip text="When you add liquidity, you are given pool tokens representing your position. These tokens automatically earn fees proportional to your share of the pool, and can be redeemed at any time." />
      </div>
      <TokensPanel
        setTokens={() => setTokens([tokens[1], tokens[0]])}
        isLiquidity
      >
        <TokenPanel
          title="Input"
          value={inputFrom}
          setValue={setInputFrom}
          token0={tokens[0]}
          tokenBalance={getShowBalance(token0Balance, tokens[0]?.decimals)}
          tokenSelectHandle={() => changeModal("token-from")}
        />
        <TokenPanel
          title="Input"
          value={calculatedInputTo}
          token0={tokens[1]}
          tokenBalance={getShowBalance(token1Balance, tokens[1]?.decimals)}
          tokenSelectHandle={() => changeModal("token-to")}
          setValue={() => inputTo}
        />
      </TokensPanel>
      {addLiquidityParams &&
        tokens[0] &&
        tokens[1] &&
        (loading ? (
          <Skeleton height={78} count={2} />
        ) : (
          <div className="card bordered">
            <InfoRow
              title="Rates"
              value={
                <>
                  1 {tokens[0]?.symbol} ={" "}
                  {`${addLiquidityParams?.rates} ${tokens[1]?.symbol}`}
                  <SwapIcon />
                </>
              }
            />
            <InfoRow
              title="Share of pool"
              value={`${addLiquidityParams && Number(addLiquidityParams.poolShare)}%`}
            />
          </div>
        ))}
      {address ? (
        <button
          className={"btn block"}
          onClick={() => {
            !isAllowanceToken0 && approveToken0(inputFrom);
            !isAllowanceToken1 && approveToken1(calculatedInputTo);
            isAllowanceToken0 && isAllowanceToken1 && changeModal("add");
          }}
          disabled={
            !!error ||
            toToken(inputTo, tokens[1]?.decimals) > BigInt(token1Balance) ||
            modal === "add"
          }
        >
          {error ??
            (isApprovingToken0 || isApprovingToken1 ? (
              "Approving..."
            ) : toToken(inputTo, tokens[1]?.decimals) >
              BigInt(token1Balance) ? (
              `Insufficient ${tokens[1]?.symbol} balance`
            ) : modal === "add" ? (
              <Processing />
            ) : !isAllowanceToken0 || !isAllowanceToken1 ? (
              `Approve ${tokens[isAllowanceToken0 ? 1 : 0]?.symbol}`
            ) : (
              "Supply"
            ))}
        </button>
      ) : (
        <button className={"btn block"} onClick={() => open()}>
          Connect wallet
        </button>
      )}
      {(modal === "token-to" || modal === "token-from") && (
        <SelectTokenModal
          toggleModal={closeModal}
          selectToken={selectToken}
          token={modal === "token-to" ? tokens[1] : tokens[0]}
        />
      )}
      {modal === "add" &&
        address &&
        walletProvider &&
        tokens[0] &&
        tokens[1] &&
        addLiquidityParams && (
          <AddTransactionModal
            token0Amount={inputFrom}
            token1Amount={calculatedInputTo}
            tokenA={getUniswapToken(tokens[0], tokenList)}
            tokenB={getUniswapToken(tokens[1], tokenList)}
            token0={tokens[0]}
            token1={tokens[1]}
            account={address}
            walletProvider={walletProvider}
            onCloseButtonClick={() => closeModal()}
            poolShare={addLiquidityParams && addLiquidityParams.poolShare}
            rates={addLiquidityParams?.rates}
            lpTokenAmount={addLiquidityParams.liquidityMinted}
          />
        )}
    </div>
  );
};
