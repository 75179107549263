import classes from "./PoolInfo.module.css";
import { PoolCard, InfoRow } from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { formatUnits } from "ethers";
import { IPosition } from "../../interfaces/IPosition";

export const PoolInfo = ({ pool }: { pool: IPosition }) => {
  const { token0, token1, userShare, percent } = pool;

  return (
    <PoolCard
      token0Icon={token0?.logoUri}
      token1Icon={token1?.logoUri}
      name={`${token0?.symbol}-${token1?.symbol}`}
      balance={userShare ? formatUnits(userShare, 9) : "-"}
    >
      <div className={classes.CardInner}>
        <div className={classNames(classes.Info, "card bordered")}>
          {/*<InfoRow*/}
          {/*  title={`Pooled ${token0?.symbol}`}*/}
          {/*  value={`${token0Amount} ${token0?.symbol}`}*/}
          {/*/>*/}
          {/*<InfoRow*/}
          {/*  title={`Pooled ${token1?.symbol}`}*/}
          {/*  value={`${token1Amount} ${token1?.symbol}`}*/}
          {/*/>*/}
          <InfoRow
            title="Your pool tokens"
            value={`${userShare ? formatUnits(userShare, 9) : "-"} ${token0?.symbol}-${token1?.symbol}`}
          />
          <InfoRow title="Your pool share" value={`${percent}%`} />
        </div>
        <div className={classes.Buttons}>
          <Link
            to={`/pools/remove/${token0?.symbol}-${token1?.symbol}`}
            className="btn secondary block"
          >
            Remove
          </Link>
          <Link
            to={`/pools/add/${token0?.symbol}-${token1?.symbol}`}
            className="btn block"
          >
            Add
          </Link>
        </div>
      </div>
    </PoolCard>
  );
};
