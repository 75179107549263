import { useEffect, useState } from "react";
import { SwapService } from "@curiodao/capital-dex-sdk/evm";
import { useNetworkContext } from "../../../Network";
import { Trade } from "@uniswap/sdk";
import { getUniswapToken } from "../../../Tokens";
import { useTokenListContext } from "../../../../contexts/TokenList";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { IToken } from "@curiodao/capital-dex-sdk";
import { getCombinationPairs } from "@curiodao/capital-dex-sdk/evm";

export const useTrade = (
  amountIn: string,
  token0: IToken | undefined,
  token1: IToken | undefined,
  isWrapping: boolean,
) => {
  const [swapParams, setSwapParams] = useState<Trade>();
  const [resError] = useState<string | undefined>(undefined);
  const { chainId, core } = useNetworkContext();
  const { tokenList } = useTokenListContext();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const tokenA = getUniswapToken(token0, tokenList);
      const tokenB = getUniswapToken(token1, tokenList);
      if (
        tokenA &&
        tokenB &&
        core?.networkCredentials.rpcUrl &&
        !isWrapping &&
        amountIn.length
      ) {
        try {
          const pairsForRoute = getCombinationPairs(
            tokenList.filter((token) => token.symbol !== "BTC"),
          );
          const swapService = new SwapService();
          const swapParams = await swapService.getEthSwapParams(
            tokenA,
            tokenB,
            amountIn,
            pairsForRoute,
            core.networkCredentials.rpcUrl,
            chainId,
            walletProvider,
            token0?.isEther,
            token1?.isEther,
          );

          setSwapParams(swapParams);
          setLoading(false);
        } catch (error) {
          console.error("Error", error);
          setLoading(false);
        }
      } else {
        setSwapParams(undefined);
        setLoading(false);
      }
    })();
  }, [
    amountIn,
    chainId,
    core?.networkCredentials.rpcUrl,
    isWrapping,
    token0,
    token1,
    tokenList,
    walletProvider,
  ]);

  return { trade: swapParams, resError, loading };
};
