import { BrowserProvider, Eip1193Provider, JsonRpcSigner } from "ethers";

export const getSigner = async (
  walletProvider: Eip1193Provider,
): Promise<JsonRpcSigner> => {
  try {
    return new BrowserProvider(walletProvider).getSigner();
  } catch (e) {
    throw `Error in initialization signer\n${e}`;
  }
};
