import { ERC20_ABI } from "../interfaces/erc20.abi";
import {
  BrowserProvider,
  Eip1193Provider,
  JsonRpcProvider,
  JsonRpcSigner,
  ethers,
} from "ethers";
import { getProvider } from "../../../../common/_evm/libs/getProvider";
import { getSigner } from "../../../../common/_evm/libs/getSigner";
import { IToken } from "@curiodao/capital-dex-sdk";

export class TokenService {
  constructor(private token: IToken) {}

  async getERC20Contract(
    providerOrSigner: JsonRpcProvider | BrowserProvider | JsonRpcSigner,
  ) {
    return new ethers.Contract(this.token.address, ERC20_ABI, providerOrSigner);
  }

  async getAllowance(
    account: string,
    spender: string,
    walletProvider: Eip1193Provider,
  ): Promise<bigint> {
    try {
      const provider = getProvider(walletProvider);
      const contract = await this.getERC20Contract(provider);
      return await contract.allowance(account, spender);
    } catch (e) {
      console.error(e);
      return 0n;
    }
  }

  async getBalance(
    account: string,
    walletProvider: Eip1193Provider,
  ): Promise<string> {
    try {
      const provider = getProvider(walletProvider);
      if (!this.token.isEther) {
        const contract = await this.getERC20Contract(provider);
        return await contract.balanceOf(account);
      }
      const ethBalance = await provider.getBalance(account);
      return ethBalance.toString();
    } catch (e) {
      console.error(e);
      return "0";
    }
  }

  async approve(
    spender: string,
    walletProvider: Eip1193Provider,
    amount?: string,
  ) {
    try {
      const signer = await getSigner(walletProvider);
      const contract = await this.getERC20Contract(signer);
      return contract.approve(spender, amount);
    } catch (e) {
      throw `Error with approve ${e}`;
    }
  }
}
