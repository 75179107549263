import { Token } from "@uniswap/sdk";
import { IToken } from "@curiodao/capital-dex-sdk";

export const getUniswapToken = (
  token: IToken | undefined,
  evmTokenList: IToken[],
): Token | undefined => {
  if (token) {
    if (token.isEther) {
      const wrappedToken = evmTokenList.find(
        (tokenItem) =>
          tokenItem.symbol.toLowerCase() === `w${token.symbol}`.toLowerCase(),
      );
      if (wrappedToken) {
        return new Token(
          wrappedToken.chainId,
          wrappedToken.address,
          wrappedToken.decimals,
          wrappedToken.symbol,
        );
      }
      return undefined;
    }
    return new Token(
      token.chainId,
      token.address,
      token.decimals,
      token.symbol,
    );
  }
  return undefined;
};
