import ether from "../../../../assets/img/tokens/ethereum.svg";
import { IToken } from "@curiodao/capital-dex-sdk";

export const ETHER: IToken = {
  name: "Ether",
  isEther: true,
  symbol: "ETH",
  address: "",
  decimals: 18,
  logoUri: ether,
  chainId: 0,
};
