import { PageDescription } from "@curiodao/capital-dex-ui-kit";
import classes from "./PoolsScreen.module.css";
import React from "react";
import img from "../../modules/Pools/assets/Pools.png";
import { Link } from "react-router-dom";

export const PoolsScreen = () => {
  return (
    <div className="card gap-16">
      <div className={classes.Hero}>
        <p className="p1 semi">Liquidity provider rewards</p>
        <PageDescription
          text="Liquidity providers earn a 0.2% fee on all trades proportional to their share of the pool. Fees are added to the pool, accrue in real time and can be claimed by withdrawing your liquidity."
          img={img}
        />
      </div>
      <Link to="add" className="btn block">
        Add liquidity
      </Link>
    </div>
  );
};
