import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const testnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.GOERLI,
    name: "Goerli",
    rpcUrl: "https://goerli.gateway.tenderly.co",
    currency: "ETH",
    explorerUrl: "https://goerli.etherscan.io",
  },
  cgt: "0xd219F93eab932441eE2c5A41b3353f0497447DF2",
  whitelist: "0xcFC8002c27985410F7a5Df76f418E5F1a460e1eb",
  router: "0xC657723A215114282B9304dd2A20Aa4d6a1bEB24",
  multicall: "0x715D0aE552FE5B04A0C3ee7078Ba2fbb804Cc2A3",
  blocksPerYear: 2336000,
  farming: [
    // chef v1
    {
      masterChef: "0xC0D56DDe2662060e0e73EbbCC0A8e379a5b4092b",
      rewardToken: "CGT",
      rewardPerMonth: 50000,
      pools: [
        {
          pid: 1,
          pairAddress: "0x3A8deC0A99725A32BCF43DB228f84De915a64835",
          token0Symbol: "ETH",
          token1Symbol: "CGT",
        },
        {
          pid: 2,
          pairAddress: "0x9fC287F189B3D0F61dC81E4F96ad2D8A2FE0Cc5f",
          token0Symbol: "ETH",
          token1Symbol: "CUR",
        },
        {
          pid: 3,
          pairAddress: "0xFb4133146f029475203c0A28a356b1091fE1a818",
          token0Symbol: "CGT",
          token1Symbol: "DAI",
        },
      ],
    },
    // chef 2
    {
      masterChef: "0x3f52B3D865fEa5B5E7D4e814D05EDcBaDe2411Ee",
      rewardToken: "CGT",
      rewardPerMonth: 66667,
      pools: [
        {
          pid: 1,
          pairAddress: "0x566982E84aCe98AAB455eB05A9942aE7A7587e6B",
          token0Symbol: "CGT",
          token1Symbol: "wCT1",
        },
        {
          pid: 2,
          pairAddress: "0x033C131F330957F590F101CF217f7E659b8d073e",
          token0Symbol: "CGT",
          token1Symbol: "CSC",
        },
      ],
    },
  ],
  cgtCurSwapContract: "0xf3A2aCE6F6ab2c3c4b2fdfaF53Ce5Bb90F1de1d7",
  wrapping: ["CT1", "wCT1"],
};
