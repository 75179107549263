import classNames from "classnames";
import classes from "./Menu.module.css";
import { MENU } from "../../constants/menu";
import { getMenu } from "../../mappers/getMenu";
import { NavLink } from "react-router-dom";
import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";

export const Menu = ({
  showMenu,
  core,
  isParachain,
  isTon,
  closeModal,
}: {
  showMenu: boolean;
  core: INetworkCore | undefined;
  isParachain: boolean;
  isTon: boolean;
  closeModal?: () => void;
}) => {
  return (
    <div
      className={classNames(
        classes.NavigationBlock,
        showMenu ? classes.ShowMenu : "",
      )}
    >
      <ul className={classes.NavigationList}>
        {getMenu(MENU, core, isParachain, isTon).map((item) => (
          <li key={item.title}>
            <NavLink
              to={item.link}
              className={`menu-item`}
              end={item.link === "/"}
              onClick={closeModal}
            >
              {item.title}
            </NavLink>
          </li>
        ))}
        <li key="Bridge">
          <a className={`menu-item`} href="https://bridge.capitaldex.exchange/">
            Bridge
          </a>
        </li>
      </ul>
    </div>
  );
};
