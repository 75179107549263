import React, { useState } from "react";
import { Modal, ProviderButton } from "@curiodao/capital-dex-ui-kit";
import Identicon from "@polkadot/react-identicon";
import classes from "./ConnectWalletModal.module.css";
import { providers } from "../../constants/providers";
import { InjectedAccountWithMeta } from "@polkadot/extension-inject/types";
import { shortAddress } from "../../../../common/libs/shortAddress";
import arrow from "../../../../assets/img/icons/arrow.svg";
import novaImg from "../../../../assets//img/wallets/nova-wallet.png";
import { useWalletContext } from "../../../../common/context/Wallet";
import { get777FormatAddress } from "../../../../common/_parachain/libs/get777FormatAddress";

export const ConnectWalletModal = ({
  toggleModal,
}: {
  toggleModal: () => void;
}): JSX.Element | null => {
  const { connect, selectAddress } = useWalletContext();
  const [selectedProvider, setSelectedProvider] = useState("");
  const [error, setError] = useState("");
  const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([]);

  const selectProvider = async (
    wallet: string,
  ): Promise<boolean | undefined> => {
    const accounts = await connect(wallet);
    if (!accounts.length) {
      return false;
    }
    setSelectedProvider(wallet);
    setAccounts(accounts);
  };

  return (
    <Modal
      onCloseButtonClick={toggleModal}
      title={selectedProvider ? "Select a address" : "Select a wallet provider"}
    >
      {!selectedProvider ? (
        <div className={classes.ButtonsGroup}>
          {providers.map((item) => (
            <ProviderButton
              onClick={() => selectProvider(item.metaName)}
              icon={item.icon}
              name={item.name}
              key={item.metaName}
            />
          ))}
          <ProviderButton
            onClick={() => {
              window.walletExtension?.isNovaWallet
                ? selectProvider("nova-wallet")
                : setError("Nova Wallet not found.");
            }}
            icon={novaImg}
            name={`Nova Wallet`}
          />
          <p className="p2">{error}</p>
        </div>
      ) : (
        <div className={classes.ButtonsGroup}>
          {accounts.map((item) => (
            <button
              className={classes.Wrapper}
              onClick={() => {
                selectAddress(item.address);
                toggleModal();
              }}
              key={item.address}
            >
              <div className={classes.Logo}>
                <Identicon value={item.address} size={32} theme="polkadot" />
              </div>
              <div>
                <p className="semi p2">{item.meta.name}</p>
                <p className="p2">
                  {shortAddress(get777FormatAddress(item.address))}
                </p>
              </div>
              <img src={arrow} />
            </button>
          ))}
        </div>
      )}
    </Modal>
  );
};
