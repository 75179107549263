import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNetworkContext } from "./useNetworkContext";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { NetworkService } from "../../services/Network.service";

export const useInitNetwork = () => {
  const [searchParams] = useSearchParams();
  const { network, setNetwork } = useNetworkContext();
  const { walletProvider } = useWeb3ModalProvider();
  const [networkService] = useState(() => new NetworkService());

  // set network from url or get defaultå
  useEffect(() => {
    if (!network || (network && !network.length)) {
      const networkFromUrl = networkService.getUrlNetwork(searchParams);
      if (
        !networkService.isParachainNetwork(networkFromUrl) &&
        walletProvider
      ) {
        networkService.switchNetwork(networkFromUrl, walletProvider);
      }
      setNetwork(networkService.getUrlNetwork(searchParams));
    }
  }, [network, networkService, searchParams, setNetwork, walletProvider]);
};
