import React, { useState } from "react";
import classes from "./Swap.module.css";
import {
  useModalByName,
  Processing,
  TokenPanel,
  TokensPanel,
} from "@curiodao/capital-dex-ui-kit";
import classNames from "classnames";
import { SelectTokenModal } from "../../../../Tokens";
import { useTonTokensPanel } from "../../../../TokensPanel";
import { getShowBalance } from "../../../../../common/libs/getShowBalance";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useSimulateSwap } from "../../hooks/useSimulateSwap";
import { SwapInfoCard } from "../SwapInfoCard";
import { formatUnits } from "ethers";
import Skeleton from "react-loading-skeleton";
import { useTokenListContext } from "../../../../../contexts/TokenList";
import { TransactionModal } from "../TransactionModal";

export const Swap = () => {
  const { modal, closeModal, changeModal } = useModalByName();
  const [tonConnectUI] = useTonConnectUI();

  const tonAddress = useTonAddress();
  const { tokenList } = useTokenListContext();
  const [inputFrom, setInputFrom] = useState("");

  const {
    tokens,
    setTokens,
    selectToken,
    token0Balance,
    token1Balance,
    error,
  } = useTonTokensPanel(tonAddress, modal, inputFrom, tokenList);

  const { trade, loading, noLiquidity } = useSimulateSwap(
    tokens[0],
    tokens[1],
    inputFrom,
  );

  // useEffect(() => {
  //   tonClient.getTransactions(tonAddress, {}).then((res) => {
  //     console.log(res);
  //   });
  // }, []);

  return (
    <div className={classNames(classes.Wrapper, "card")}>
      <div className={classNames(classes.Headline, "space-between")}>
        <p className="p1 semi">Swap</p>
      </div>
      <TokensPanel setTokens={() => setTokens([tokens[1], tokens[0]])}>
        <TokenPanel
          token0={tokens[0]}
          title="From"
          tokenBalance={getShowBalance(token0Balance, tokens[0]?.decimals)}
          setValue={setInputFrom}
          value={inputFrom}
          tokenSelectHandle={() => changeModal("token-from")}
        />
        <TokenPanel
          token0={tokens[1]}
          title="To"
          tokenBalance={getShowBalance(token1Balance, tokens[1]?.decimals)}
          value={
            trade?.ask_units
              ? formatUnits(trade.ask_units, tokens[1]?.decimals)
              : ""
          }
          tokenSelectHandle={() => changeModal("token-to")}
        />
      </TokensPanel>
      {loading ? (
        <Skeleton height={137} />
      ) : (
        trade && <SwapInfoCard tokens={tokens} trade={trade} />
      )}
      {tonAddress ? (
        <button
          className={"btn block"}
          onClick={() => changeModal("swap")}
          disabled={!!error || noLiquidity || modal === "swap"}
        >
          {error ??
            (noLiquidity ? (
              "No liquidity"
            ) : modal === "swap" ? (
              <Processing />
            ) : (
              "Swap"
            ))}
        </button>
      ) : (
        <button
          className={"btn block"}
          onClick={() => tonConnectUI.openModal()}
        >
          Connect wallet
        </button>
      )}
      {(modal === "token-to" || modal === "token-from") && (
        <SelectTokenModal
          toggleModal={closeModal}
          selectToken={selectToken}
          token={modal === "token-to" ? tokens[1] : tokens[0]}
        />
      )}
      {modal === "swap" && tokens[0] && tokens[1] && trade && (
        <TransactionModal
          inputFrom={inputFrom}
          token0={tokens[0]}
          token1={tokens[1]}
          swapParams={trade}
          accountId={tonAddress}
          onCloseButtonClick={closeModal}
        />
      )}
    </div>
  );
};
