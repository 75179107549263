import { IToken } from "@curiodao/capital-dex-sdk";
import { ChainId } from "@uniswap/sdk";

export const customEth = (isTestnet: boolean): IToken => {
  return {
    name: "BNB",
    logoUri:
      "https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/binance/info/logo.png",
    symbol: "BNB",
    decimals: 18,
    chainId: isTestnet ? ChainId.BSC_TESTNET : ChainId.BSC,
    address: "",
    isEther: true,
  };
};
