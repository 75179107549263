import "curio-parachain-ts-interfaces/index.js";
import "normalize.css";
import "buffer";
import "@curiodao/capital-dex-ui-kit/dist/css/index.ts";
import "@curiodao/capital-dex-ui-kit/dist/css/inter.css";
import "react-loading-skeleton/dist/skeleton.css";

import React from "react";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { createRoot } from "react-dom/client";
import { WalletProvider } from "./common/context/Wallet";
import { App } from "./App";
import { NetworkProvider } from "./modules/Network";
import { TokenListProvider } from "./contexts/TokenList/TokenListContext";
import WebApp from "@twa-dev/sdk";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

WebApp.ready();
const queryClient = new QueryClient();

createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={queryClient}>
    <WalletProvider>
      <TonConnectUIProvider
        manifestUrl={"https://capitaldex.exchange/tonconnect-manifest.json"}
      >
        <NetworkProvider>
          <TokenListProvider>
            <App />
          </TokenListProvider>
        </NetworkProvider>
      </TonConnectUIProvider>
    </WalletProvider>
  </QueryClientProvider>,
);
