import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { ChainId } from "@uniswap/sdk";

export const mainnet: INetworkCore = {
  networkCredentials: {
    chainId: ChainId.SKALE,
    name: "SKALE",
    rpcUrl: "https://mainnet-api.skalenodes.com/v1/fit-betelgeus",
    currency: "skETH",
    explorerUrl: "https://fit-betelgeuse.explorer.mainnet.skalenodes.com",
  },
  cgt: "0x134EbAb7883dFa9D04d20674dd8A8A995fB40Ced",
  whitelist: "0x1Da4933B725Afc12C6cCA017d71bBb06d5b096Ef",
  router: "0xB88AF58B511Cb378673A8709E42dA0bF013162fa",
  multicall: "0x211dA8112F2bD1c493a4F9158b9Aa1fa7342a8E8",
  blocksPerYear: 2866909,
  farming: [
    {
      masterChef: "0x9D90A4E704232E3E393059Dc29d36Aa58b61e70b",
      rewardToken: "CGT",
      pools: [
        {
          pairAddress: "0x3fbc00B15503a1868065c160C28e84E134eD2086",
          pid: 0,
          token0Symbol: "CGT",
          token1Symbol: "DAI",
        },
        {
          pairAddress: "0x71dD63Ce027047065467bba3a18CBb9C2AcF660F",
          pid: 1,
          token0Symbol: "CGT",
          token1Symbol: "ETH",
        },
        {
          pairAddress: "0x65d259e6fbD960b3C9e2AB010e8cB5BEDB33FDf8",
          pid: 2,
          token0Symbol: "CGT",
          token1Symbol: "CSC",
        },
        {
          pairAddress: "0x4D3114f79b5BD3fefc7A9960E08DD131F1C4767b",
          pid: 3,
          token0Symbol: "CGT",
          token1Symbol: "wCT1",
        },
        {
          pairAddress: "0x5D2Be1907e3011B072b8D8A694b599864E111459",
          pid: 4,
          token0Symbol: "CGT",
          token1Symbol: "SKL",
        },
        {
          pairAddress: "0x0631A0d58AfBA02dcC774c0a98153Ef20fDa48c3",
          pid: 5,
          token0Symbol: "CGT",
          token1Symbol: "USDC",
        },
        {
          pairAddress: "0xb00a81A76E96462c7776eE355c13fB09cb301b60",
          pid: 6,
          token0Symbol: "CGT",
          token1Symbol: "LINK",
        },
      ],
    },
  ],
};
