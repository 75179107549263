import React from "react";
import classes from "./Earned.module.css";
import {
  FarmsBalanceCard,
  PageDescription,
} from "@curiodao/capital-dex-ui-kit";
import img from "../../../assets/Farms.png";
import cgtToken from "../../../../../assets/img/icons/cgt-token.svg";
import { useWalletContext } from "../../../../../common/context/Wallet";
import {
  HARVEST_TOKENS,
  REWARD_PER_MONTH,
} from "../../constants/parachain-pools";
import { useTotalIssuance } from "../../hooks/useTotalIssuance";
import { Balance } from "../Balance";
import Skeleton from "react-loading-skeleton";

export const EarnedSection = () => {
  const { account, api } = useWalletContext();
  const { totalIssuance, loading } = useTotalIssuance(api);

  return (
    <div className="card">
      <div className={classes.Hero}>
        <p className="p1 semi">Earn CGT by staking CLP</p>
        <PageDescription
          img={img}
          text="In addition to the standard rewards for liquidity providers as exchange fees from traders, farming pools allow you to get rewards in CGT."
        />
      </div>
      <div className={classes.Cards}>
        <FarmsBalanceCard
          title="Total supply CGT"
          text="Rewards per month"
          value={
            loading ? (
              <Skeleton height={40} count={1} />
            ) : (
              totalIssuance?.dsp ?? "-"
            )
          }
          tokenIcon={cgtToken}
          balance={`${REWARD_PER_MONTH.CGT} CGT`}
        />
        {HARVEST_TOKENS.map((token) => (
          <Balance
            key={token}
            api={api}
            account={account}
            tokenSymbol={token}
          />
        ))}
      </div>
    </div>
  );
};
