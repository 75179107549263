import { useEffect, useState } from "react";
import { useBlock } from "../../../../common/hooks/useBlock";
import { TokenService } from "../services/Token.service";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers/react";
import { IToken } from "@curiodao/capital-dex-sdk";

export const useTokenBalance = (token: IToken | undefined) => {
  const [balance, setBalance] = useState("0");
  const block = useBlock();
  const { address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (address && walletProvider) {
      (async () => {
        if (token) {
          setLoading(true);
          const tokens = new TokenService(token);
          const balance = await tokens.getBalance(address, walletProvider);
          setBalance(balance.toString());
          setLoading(false);
        }
      })();
    } else {
      setBalance("0");
    }
  }, [address, token, block, walletProvider]);

  return { balance, loading };
};
