import { IToken } from "@curiodao/capital-dex-sdk";
import { JettonsService } from "../services/jettons.service";
import { tonDexClient } from "../../../../config/clients";
import { useTonAddress } from "@tonconnect/ui-react";
import { useQuery } from "@tanstack/react-query";

export const useTokenBalance = (token: IToken | undefined) => {
  const tonAddress = useTonAddress();

  const { data } = useQuery({
    queryKey: ["fetchBalances", tonAddress],
    queryFn: () => new JettonsService(tonDexClient).fetchBalances(tonAddress),
    staleTime: 5 * 1000,
  });

  const { data: balanceTon } = useQuery({
    queryKey: ["fetchTonBalance", tonAddress],
    queryFn: () => new JettonsService(tonDexClient).fetchBalanceTon(tonAddress),
    staleTime: 5 * 1000,
  });

  const balance =
    (token?.isEther
      ? balanceTon
      : data?.find((balance) => balance.jetton.address === token?.address)
          ?.balance) ?? "0";
  return { balance };
};
